import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";


// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components


function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            {/* <img src="https://res.cloudinary.com/no10labs/image/upload/v1612565832/logo_white.png"></img> */}
            {/* <NavbarBrand id="navbar-brand" to="/index" tag={Link}> */}
         
            <img id="top" className="logo-nav-custom" src="https://res.cloudinary.com/no10labs/image/upload/v1612579877/Paradise_Law_Firm_Logo_Feb_5-WHITE.svg"></img>
              {/* Paradise Law Group, PC */}
            {/* </NavbarBrand> */}
            
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
  
              <NavItem>
                <NavLink  href="/#who"> Who we are</NavLink>
              </NavItem>

              <NavItem>
                <NavLink  href="/#opinions">Opinions</NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink  href="#team">Team</NavLink>
              </NavItem>

              <NavItem>
                <NavLink  href="#lenders">Lenders</NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="#fees">Fees</NavLink>
              </NavItem>
  
              <NavItem>
                <NavLink href="#careers">Careers</NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="#contact">Contact</NavLink>
              </NavItem>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;

import * as emailjs from 'emailjs-com';
/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components


// handleSubmit(e) {
//   e.preventDefault();
//   this.setState({ loading: true });

//   const { email, subject, message } = this.state

//   let templateParams = {
//     from_name: email,
//     to_name: 'chadd@no10labs.com',
//     subject: subject,
//     message_html: message,
//   }

//   emailjs.send(
//     'service_sgbd6ca',
//     'template_jzsxfg9',
//     templateParams,
//     'user_GXK0fRrRYNuBYBf5ACCFF'
//   )
//     .then((result) => {
//       this.handleShow();
//       console.log(result.text);
//     }, (error) => {
//       console.log(error.text);
//     });
// }

// resetForm() {
//   this.setState({
//     email: '',
//     subject: '',
//     message: '',
//     modal: false,
//   })
// }

// handleChange = (param, e) => {
//   this.setState({ [param]: e.target.value })
// }

function ContactForm() {

  return (
    <>
<div id="testimonials" className="section-dark landing-section cd-section">
  <Container>
    <Row>
      <Col className="ml-auto mr-auto" md="8">
        <h2 className="text-center">Get in touch</h2>
        <Form className="contact-form">
          <Row>
            <Col md="6">
              <label>Name</label>
              <Input placeholder="Name" />
            </Col>
            <Col md="6">
              <label>Email</label>
              <Input placeholder="Email" />
            </Col>
          </Row>
          <label>Message</label>
          <Input
            placeholder="Tell us your thoughts and feelings..."
            type="textarea"
            rows="4"
          />
          <Row>
            <Col className="offset-md-4" md="4">
              <Button className="btn-fill" color="danger" size="lg">
                Send Message
                      </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </Container>
</div>

    </>
  );
}

export default ContactForm;
import React from "react";


import LandingPage from "views/examples/LandingPage.js";
import ReactGa from 'react-ga';

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    ReactGa.initialize("UA-189245934-2");
    ReactGa.pageview('pageview index');
    document.body.classList.add("index-page");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <>
      <LandingPage  />
    </>
  );
}

export default Index;

import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footers/Footer.js";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactForm from "components/ContactForm.js";
import SectionProject from "../sections-sections/SectionProject.js";
import SectionFeature from "../sections-sections/SectionFeature.js";
import Sections from "views/Sections.js";

import { Element } from 'react-scroll'

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("landing-page");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("landing-page");
  //   };
  // });
  return (
    <>
      <ColorNavbar />

      {/* <Sections/> */}
      <LandingPageHeader />
      <div className="wrapper">
        <Element><div id="who" className="section section-feature  text-center landing-section cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className=" title title-uppercase section-title">Who we are</h2>
                <h5>
                  Paradise Law Group is a boutique law firm focusing on legal opinions for real estate loan transactions. Kate Paradise, the principal of Paradise Law Group has over fifteen years experience as a commercial real estate attorney, representing lenders and borrowers in a variety of financing matters across asset classes. Our team of seasoned legal professionals is steeped in a deep understanding of real estate law, offering unparalleled insight and precision in each carefully crafted legal opinion.
                </h5>
                <br />

              </Col>
            </Row>
            <br />
            <br />

          </Container>
        </div>
        </Element>
        <div id="opinions" className="section section-dark text-center landing-section cd-section">
          <Container>
            <h2 className="title title-uppercase section-title">Opinions and Jurisdictions</h2>
            <h5>
              Paradise Law Group provides comprehensive legal opinions for real estate transactions in multiple jurisdictions.
            </h5>
            <br />

            <Row>
              <Col md="6">
                <div className="info">
                  <div className="icon icon-danger">
                    {/* <i className="nc-icon nc-palette" /> */}
                  </div>


                  <div className="list-with-heading-left">
                    <h4 className="info-title title-uppercase section-title " style={{fontWeight: 300 }}>Opinion Types</h4>
                    <ul>
                      <li>Enforceability</li>
                      <li>Entity (formation, authority, etc.)</li>
                      <li>Authority to File Bankruptcy</li>
                      <li>Single Member LLC/Non-Dissolution</li>
                      <li>Substantive Non-Consolidation</li>
                      <li>UCC Matters</li>
                      <li> Additional Matters</li>
                    </ul>
                  </div>


                </div>
              </Col>
              <Col md="6">
                <div className="info" style={{ maxWidth: '100%' }}>
                  <div className="icon icon-danger">
                    {/* <i className="nc-icon nc-bulb-63" /> */}
                  </div>
                  <div className="list-with-heading-right">
                    <h4 className="info-title title-uppercase section-title" style={{ fontWeight: 300 }}>Jurisdictions</h4>

                    <ul>
                      <li className="types">California</li>
                      <li>Delaware</li>
                      <li>District of Columbia</li>
                      <li>Florida</li>
                      <li>Illinois</li>
                      <li>Indiana </li>
                      <li>Maryland</li>
                      <li>New York</li>
                      <li>Tennessee</li>
                      <li>Virginia</li>
                    </ul>

                  </div>
                </div>
              </Col>

            </Row>

          </Container>
        </div>


        <div id="why" className="section text-center landing-section cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title title-uppercase section-title">WHY US</h2>
                <h5>
                  Paradise Law Group provides big-firm quality without the big-firm overhead. As a small firm, we are able to provide complex legal opinions for flat fees at competitive rates.    <br />     <br /> Our structure allows us to determine our fees at the outset of a deal, so borrowers can anticipate and account for opinion costs early in a transaction.     <br />    <br />We work seamlessly with borrower’s deal counsel and lender’s counsel to finalize opinions, and invoice in advance so our fees may be paid out of escrow at closing. This allows borrowers and deal counsel to focus on negotiating the transaction at hand, while we handle all legal opinion matters.
                </h5>
                <br />
              </Col>
            </Row>
            <br />
            <br />
          </Container>
        </div>
        <SectionProject />
        <SectionFeature />
        <div id="fees" className="section section-dark text-center landing-section cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title title-uppercase section-title ">FEES</h2>
                <h5>
                  Paradise Law Group offers real estate legal opinions for flat fees at competitive rates. We provide our fee structure upfront so deal teams can anticipate and plan for our fees, which are typically paid out of escrow at closing. <br />  <br />Please contact us for pricing and fee quotes via email or phone.
                </h5>
                <br />
                {/* <h5><a href="mailto:kate@paradiselawgroup.com">kate@paradiselawgroup.com</a></h5>
                <br /><h5>
                  <a href="tel:4242568723" onclick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"><p>(424) 256-8723</p></a>
                </h5> */}
               
              </Col>
            </Row>
            <br />
            <br />

          </Container>
        </div>


        <div id="careers" className="section text-center landing-section cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title title-uppercase section-title">CAREERS</h2>
                <h5>

                     
                We are always on the lookout for talented and dedicated professionals to join our team. Whether you are an experienced attorney, a skilled paralegal, or an efficient administrative professional, we offer a dynamic and supportive work environment that fosters growth and excellence.
                <br />  <br />
As a distributed team, we value autonomy and extreme ownership. Our professionals have the flexibility to work from various locations while maintaining a high level of responsibility and accountability. 

If you are interested in joining our team but don't see an opening, please feel free to send your resume and a cover letter to Chadd Bennett at <a href="mailto:chadd@paradiselawgroup.com">chadd@paradiselawgroup.com</a>


<br />  <br />

               </h5>
               

              </Col>
            </Row>
            <br />
            <br />

          </Container>
        </div>

        <div id="contact" className="section section-dark text-center landing-section cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title title-uppercase">Contact</h2>
                <h5>
                  1112 Montana Ave #383<br />
                  Santa Monica, CA 90403
                </h5>
                <br />
                <h5 ><a style={{ color: 'white' }} href="mailto:kate@paradiselawgroup.com">kate@paradiselawgroup.com</a></h5>
                <br /><h5>
                  <a style={{ color: 'white' }} href="tel:4242568723" onclick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"><p>(424) 256-8723</p></a>
                </h5>
                <a style={{ color: 'white'}} href="https://res.cloudinary.com/no10labs/raw/upload/v1621620427/Kate_Paradise.vcf"><div><i class="nc-icon nc-badge"></i><span> vCard</span></div></a>
                {/* <a className="arrow"></a> */}
              </Col>
            </Row>
            <br />
            <br />

          </Container>
        </div>
        <div id="testimonials" className="section-dark landing-section cd-section display-none">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center title-uppercase">Get in touch</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      {/* <Input placeholder="Name" /> */}
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <Input placeholder="Email" />
                    </Col>
                  </Row>
                  <label>Message</label>
                  {/* <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                  /> */}
                  <Row>
                    {/* <Col className="offset-md-4" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send Message
                      </Button>
                    </Col> */}
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <ContactForm/> */}

      </div>
      <Footer />
    </>
  );
}

export default LandingPage;

/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SectionProject() {
  const [activePill, setActivePill] = React.useState("2");
  return (
    <>
      <div className="section section-dark section-project cd-section" id="lenders">
        {/* ********* PROJECTS 1 ********* */}
        <div className="projects-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">



                <h2 className="title title-uppercase section-title">lenders</h2>
                <h5>
                  Paradise Law Group provides legal opinions to a variety of commercial real estate lenders, and has given legal opinions to lenders including the following:
                </h5>


              </Col>
              <div className="project-pills">
                <Nav className="nav-pills-danger" pills>
                  {/* <NavItem>
                    <NavLink
                      className={activePill === "1" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("1");
                      }}
                    >
                      All categories
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={activePill === "2" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("2");
                      }}
                    >
                      CMBS & Portfolio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "3" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("3");
                      }}
                    >
                      Agencies/GSE
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={activePill === "4" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("4");
                      }}
                    >
                      Borrowers Counsel
                    </NavLink >
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      className={activePill === "5" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("5");
                      }}
                    >
                      Clients
                    </NavLink>
                  </NavItem> */}
                </Nav>
                {/* Pill panes */}
                <TabContent activeTab={"pill-" + activePill}>
                  <TabPane  tabId={"pill-" + activePill} />
                  <TabPane  tabId={"pill-" + activePill} />
                  <TabPane  tabId={"pill-" + activePill} />
                  <TabPane  tabId={"pill-" + activePill} />
                  <TabPane  tabId={"pill-" + activePill} />
                </TabContent>
              </div>
            </Row>
            <div className="space-top" />
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                {activePill === "2" ? <div>

                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>ACORE Capital Mortgage, LP</h5>
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Ares Commercial Real Estate Management LLC</h5>
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Athene Annuity and Life Company</h5>
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Bank of America, N.A.</h5>
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Barclays Capital Real Estate Inc.</h5>
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Citibank, N.A.</h5>   
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Goldman Sachs Bank USA</h5>   
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>JPMorgan Chase Bank</h5>   
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Morgan Stanley, N.A.</h5>   
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>State Farm Life Insurance Company</h5>   
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>TPG Real Estate</h5>   
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Wells Fargo Bank, N.A.</h5>                   
                </div>
                  : null}

                {activePill === "3" ? <div>
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Berkadia Commercial Mortgage LLC </h5> 
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Berkeley Point Capital LLC </h5> 
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>CBRE Capital Markets, Inc. </h5> 
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>KeyBank National Association </h5> 

                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>NorthMarq Capital, LLC </h5> 
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Prudential Affordable Mortgage Company LLC </h5> 
                  <h5 className="card-category title-uppercase" style={{fontWeight: 400}}>Walker & Dunlop, LLC </h5> 
        
                </div> : null}
                {/* {activePill === "4" ? <div>
                  <h5 className="card-category">ALSTON & BIRD LLP</h5>
                  <h5 className="card-category">NEWMEYER DILLION LLP</h5>
                  <h5 className="card-category">HOLLAND & KNIGHT LLP</h5>
                  <h5 className="card-category">BROWNSTEIN HYATT FARBER SCHRECK, LLP</h5>
                  <h5 className="card-category">MCCULLOUGH HILL LEARY, PS</h5>
                  <h5 className="card-category">BAKERHOSTETLER</h5>
           
                </div> : null} */}
                {/* {activePill === "5" ? <div>
                  <h5 className="card-category">TURNBRIDGE</h5>
                  <h5 className="card-category">29TH STREET</h5>
                  <h5 className="card-category">INDUSTRIAL REALTY GROUP LLC</h5>
                  <h5 className="card-category">TRUAMERICA</h5>
                  <h5 className="card-category">TIDES EQUITIES</h5>
                  <h5 className="card-category">DINAPOLI CAPITAL PARTNERS</h5>

                </div> : null} */}
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PROJECTS 1 ********* */}

      </div>
    </>
  );
}

export default SectionProject;

import React from "react";
// reactstrap components

// core components
// import WhiteNavbar from "components/Navbars/WhiteNavbar.js";

// sections for this page
import SectionFeature from "./sections-sections/SectionFeature.js";


function Sections() {
  document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("section-page");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;

  //   var href = window.location.href.substring(
  //     window.location.href.lastIndexOf("#/") + 2
  //   );
  //   var hrefId = href.substring(href.lastIndexOf("#") + 1);
  //   if (href.lastIndexOf("#") > 0) {
  //     document.getElementById(hrefId).scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //       inline: "nearest",
  //     });
  //   }
  //   // this function is used to make the right bullets list
  //   // (the bellow <nav id="cd-vertical-nav">)
  //   // active when changeing the section on scroll
  //   const updateView = () => {
  //     var contentSections = document.getElementsByClassName("cd-section");
  //     var navigationItems = document
  //       .getElementById("cd-vertical-nav")
  //       .getElementsByTagName("a");

  //     for (let i = 0; i < contentSections.length; i++) {
  //       var activeSection =
  //         parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
  //       if (
  //         contentSections[i].offsetTop - window.innerHeight / 2 <
  //           window.pageYOffset &&
  //         contentSections[i].offsetTop +
  //           contentSections[i].scrollHeight -
  //           window.innerHeight / 2 >
  //           window.pageYOffset
  //       ) {
  //         navigationItems[activeSection].classList.add("is-selected");
  //       } else {
  //         navigationItems[activeSection].classList.remove("is-selected");
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", updateView);
  //   return function cleanup() {
  //     document.body.classList.remove("section-page");
  //     window.removeEventListener("scroll", updateView);
  //   };
  // });
  return (
    <>

      <div className="section-space" />
      {/* <SectionHeader /> */}

      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              data-number="1"
              href="#headers"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("headers").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Home</span>
            </a>
          </li>
          <li>
            <a
              data-number="2"
              href="#who"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("who").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Who we are</span>
            </a>
          </li>
          <li>
            <a
              data-number="3"
              href="#opinions"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("opinions").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">opinions</span>
            </a>
          </li>
          <li>
            <a
              data-number="4"
              href="#why"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("why").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Why</span>
            </a>
          </li>
          <li>
            <a
              data-number="5"
              href="#lenders"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("lenders").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Lenders</span>
            </a>
          </li>
          <li>
            <a
              data-number="6"
              href="#fees"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("fees").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Pricing</span>
            </a>
          </li>

          <li>
            <a
              data-number="6"
              href="#careers"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("careers").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Careers</span>
            </a>
          </li>


          {/* <li>
            <a
              data-number="7"
              href="#careers"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("careers").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Careers</span>
            </a>
          </li> */}

          <li>
            <a
              data-number="8"
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("contact").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Contact Us</span>
            </a>
          </li>

          <li>
            <a
              data-number="8"
              href="#testimonials"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("testimonials").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Testimonials</span>
            </a>
          </li>

        </ul>
      </nav>
  
    </>
  );
}

export default Sections;
